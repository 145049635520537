import { Breadcrumb, Button, Card, Col, Divider, Modal, Row, message } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import UserTable from '@/components/users/UsersTable';
// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// API
import APIService from '@/services/API';
// Interfaces
import { User } from '@/interfaces/user.interface';
import UsersModal from '@/components/users/UsersModal';

const UsersScreen = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    $skip: '0',
    $limit: '10',
    '$sort[full_name]': '1',
  });
  const [modal, setModal] = useState({
    type: 'create',
    visible: false,
    data: {},
  });
  const { isLoading, data, refetch } = useQuery(['users', params], () =>
    APIService.getUsers(params),
  );
  const handleDeleteUser = async (id: string) => {
    const response = await APIService.deleteUser(id);
    if (response.ok) {
      refetch();
      message.success(t('users.deleteSuccess'));
    } else {
      message.error(response.data?.message);
    }
  };
  const onDeleteUser = (id: string) => {
    Modal.confirm({
      title: t('users.deleteConfirmation'),
      okText: t('general.yes'),
      onOk: () => handleDeleteUser(id),
    });
  };
  const onEditUser = (data: User) => {
    // Hanlde edit user, open edition modal
    setModal({
      visible: true,
      type: 'edit',
      data,
    });
  };
  return (
    <div className="dashboard-screen fadeIn">
      <UsersModal
        visible={modal.visible}
        edit={modal.type === 'edit'}
        data={modal.data}
        onOk={() => {
          refetch();
          setModal({ visible: false, type: 'create', data: {} });
        }}
        onCancel={() => {
          setModal({ visible: false, type: 'create', data: {} });
        }}
      />
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <UserOutlined />
                      <span>{t('users.users')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() =>
                setModal({
                  visible: true,
                  type: 'create',
                  data: {},
                })
              }
            >
              {t('users.createUser')}
            </Button>
          </Col>
        </Row>
        <Divider />
        <UserTable
          current={(data?.skip || 0) / (data?.limit || 1)}
          users={data?.data}
          loading={isLoading}
          total={data?.total}
          onDelete={onDeleteUser}
          onEdit={onEditUser}
          onFilter={setParams}
        />
      </Card>
    </div>
  );
};

export default UsersScreen;
