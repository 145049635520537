import React, { useEffect } from 'react';
import { Modal, Form, message } from 'antd';
import dayjs from 'dayjs';
import UsersForm from '../forms/Users.form';
// Hooks
import { useTranslation } from 'react-i18next';
// Interfaces
import { User, UserCreation } from '@/interfaces/user.interface';
import APIService from '@/services/API';

export interface UsersModalProps {
  visible: boolean;
  edit?: boolean;
  data?: Record<string, string | number>;
  onCancel?: () => void;
  onOk?: () => void;
}
const UsersModal: React.FC<UsersModalProps> = ({
  visible,
  edit,
  onCancel,
  onOk,
  data,
}) => {
  const [formRef] = Form.useForm<User | UserCreation>();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (edit && data) {
      formRef.setFieldsValue({
        ...data,
        birthday: data.birthday ? dayjs(data.birthday) : undefined,
      });
    }
  }, [edit, data, formRef]);
  const handleOnSubmit = async (values: User | UserCreation) => {
    setLoading(true);
    const response = data?._id
      ? await APIService.updateUser(data?._id as string, values)
      : await APIService.createUser(values);
    if (response.ok) {
      if (onOk) {
        onOk();
      }
      formRef.resetFields();
      if (edit) {
        message.success(t('users.updateSuccess'));
      } else {
        message.success(t('users.createSuccess'));
      }
    }

    setLoading(false);
  };
  return (
    <Modal
      centered
      open={visible}
      title={edit ? t('users.edit') : t('users.create')}
      onOk={() => formRef.submit()}
      onCancel={() => {
        onCancel?.();
        formRef.resetFields();
      }}
      okButtonProps={{ loading }}
    >
      <UsersForm formRef={formRef} edit={edit} onSubmit={handleOnSubmit} />
    </Modal>
  );
};
export default UsersModal;
