import React from 'react';
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
// Hooks
import { useTranslation } from 'react-i18next';
// Services
import APIService from '@/services/API';
// Interfaces
import { User, UserCreation } from '@/interfaces/user.interface';
import { validateSlugField, validatePhoneField } from '@/utils/validations';
import { useQuery } from 'react-query';

export interface UsersFormProps {
  formRef: FormInstance<User | UserCreation>;
  edit?: boolean;
  onSubmit?: (values: User | UserCreation) => Promise<void>;
}
const UsersForm: React.FC<UsersFormProps> = ({ formRef, edit = true, onSubmit }) => {
  const { t } = useTranslation();
  const { isLoading, data } = useQuery(['roles'], () => APIService.getRoles());
  return (
    <Form name="user" form={formRef} onFinish={onSubmit} layout="vertical">
      <Row gutter={[10, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={t('users.fullName')}
            name="full_name"
            rules={[
              { required: true, type: 'string', min: 5, max: 50, whitespace: true },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={24} md={12}>
          <Form.Item
            label={t('users.email')}
            name="email"
            rules={[{ required: true, type: 'email', whitespace: true }]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={24} sm={12}>
          <Form.Item
            label={t('users.username')}
            name="username"
            rules={[
              {
                max: 30,
                min: 4,
                required: true,
                type: 'string',
                whitespace: true,
              },
              {
                validator: validateSlugField,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={24} sm={12}>
          <Form.Item
            label={t('users.birthday')}
            name="birthday"
            rules={[{ required: false, type: 'date' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={t('users.password')}
            name="password"
            rules={[
              { required: !edit, type: 'string', min: 5, max: 30, whitespace: true },
            ]}
          >
            <Input.Password type="password" />
          </Form.Item>
        </Col>

        <Col span={24} md={12}>
          <Form.Item
            label={t('users.passwordConfirmation')}
            name="rpassword"
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: !edit, type: 'string', min: 5, max: 30, whitespace: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('users.passwordsDontMatch') as string),
                  );
                },
              }),
            ]}
          >
            <Input.Password type="password" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={t('users.role')}
            name="rol_id"
            rules={[{ required: true, type: 'string', len: 24, whitespace: true }]}
          >
            <Select
              loading={isLoading}
              style={{ width: '100%' }}
              options={data?.data.map((role) => ({
                label: role.name,
                value: role._id,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={24} md={12}>
          <Form.Item
            label={t('general.status')}
            name="status"
            initialValue={1}
            rules={[{ required: true, message: <>{t('users.required')}</> }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  label: t('general.active'),
                  value: 1,
                },
                {
                  label: t('general.inactive'),
                  value: 0,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default UsersForm;
