import { Button, Table, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import type { ColumnsType } from 'antd/es/table';
import { User } from '@/interfaces/user.interface';
import { parseFilter } from '@/utils/filter';
import { FilterByRegex, FilterByStatus, FilterByIcon } from '../utils/CommonFilters';

export interface UserTableProps {
  users?: User[];
  total?: number;
  current?: number;
  loading?: boolean;
  onEdit?: (data: User) => void;
  onDelete?: (id: string) => void;
  onFilter?: (value: any) => any;
}
const UserTable = ({
  users,
  total,
  onEdit,
  onDelete,
  onFilter,
  loading,
}: UserTableProps) => {
  const { t } = useTranslation();

  const UserColumns: ColumnsType<User> = [
    {
      title: t('users.fullName'),
      dataIndex: 'full_name',
      key: 'full_name',
      sorter: true,
      defaultSortOrder: 'ascend',
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
      key: 'email',
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('users.role'),
      dataIndex: ['role', 'name'],
      key: 'role.name',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      filters: [
        {
          text: t('general.active'),
          value: '1',
        },
        {
          text: t('general.unverified'),
          value: '0',
        },
        {
          text: t('general.inactive'),
          value: '2',
        },
      ],
      filterDropdown: FilterByStatus([
        {
          label: t('general.active'),
          value: '1',
        },
        {
          label: t('general.unverified'),
          value: '0',
        },
        {
          label: t('general.inactive'),
          value: '2',
        },
      ]),
      key: 'status',
      render: (status: number) =>
        status === 1
          ? t('general.active')
          : status === 2
          ? t('general.inactive')
          : t('general.unverified'),
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (text: string, record: User) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit?.(record)}>
            {t('general.edit')}
          </Button>
          <Button type="link" onClick={() => onDelete?.(record._id)}>
            {t('general.delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (pagination: any = {}, filters: any = {}, sorter: any = {}) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...parseFilter(filters, value, sorter),
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={users}
      columns={UserColumns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 10,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default UserTable;
